a, a:hover {
  text-decoration: none;
}
.lead a, .lead a:hover {  
  color: #ECCD1E;
}

.button {
  background-color: #3B3A3A;
  color: #ECCD1E;
  padding: 10px;
}

.thank-you {
  margin-top: 150px;
  margin-bottom: 50px;
  text-align: center;
}
.footnotes {
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
}

.ship-wrap {
    margin: auto;
    border-radius: 50%;
    overflow: hidden;
    width: 300px;
    height: 300px;
    background-color: #aae7ff;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .ship-wrap .wave {
    position: absolute;
    width: 800px;
    -webkit-transform-origin: center center;
            transform-origin: center center;
  }
  .ship-wrap .wave:before {
    content: "";
    position: absolute;
    left: 0;
    top: -6.6666666667px;
    right: 0;
    background-repeat: repeat;
    height: 6.6666666667px;
    background-size: 20px 20px;
    background-image: radial-gradient(circle at 6.6666666667px -3.3333333333px, transparent 8px, #91b9ff 8px);
  }
  .ship-wrap .wave:after {
    content: "";
    position: absolute;
    left: 0;
    top: -10px;
    right: 0;
    background-repeat: repeat;
    height: 10px;
    background-size: 40px 20px;
    background-image: radial-gradient(circle at 6.6666666667px 10px, #91b9ff 8px, transparent 8.6956521739px);
  }
  .ship-wrap .wave1 {
    height: 400px;
    background-color: #91b9ff;
    opacity: .8;
    -webkit-animation: wave1 15s ease-in-out infinite;
            animation: wave1 15s ease-in-out infinite;
    bottom: -307.6923076923px;
    z-index: 3;
  }
  .ship-wrap .wave2 {
    height: 400px;
    background-color: #91b9ff;
    opacity: .4;
    left: -30%;
    -webkit-animation: wave2 20s ease-out infinite;
            animation: wave2 20s ease-out infinite;
    bottom: -296.2962962963px;
    z-index: 1;
  }
  .ship-wrap .main {
    width: 100px;
    height: 22.2222222222px;
    background-color: #684000;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 88.8888888889px;
    z-index: 2;
    -webkit-animation: ship 3s linear infinite;
            animation: ship 3s linear infinite;
  }
  .ship-wrap .main:before, .ship-wrap .main:after {
    content: '';
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    border: 0;
  }
  .ship-wrap .main:before {
    left: -22.2222222222px;
    border-top: 22.2222222222px solid #684000;
    border-left: 22.2222222222px solid transparent;
    border-bottom: 22.2222222222px solid transparent;
  }
  .ship-wrap .main:after {
    right: -22.2222222222px;
    border-top: 22.2222222222px solid #684000;
    border-bottom: 22.2222222222px solid transparent;
    border-right: 22.2222222222px solid transparent;
  }
  .ship-wrap .main-mast {
    width: 2.5px;
    height: 100px;
    background-color: #684000;
    position: absolute;
    right: 20%;
    bottom: 22.2222222222px;
    z-index: 2;
  }
  .ship-wrap .main-course {
    width: 50px;
    height: 80px;
    background-color: #fff;
    position: absolute;
    right: 20%;
    top: -95.2380952381px;
    border-top-left-radius: 100%;
  }
  .ship-wrap .main-course:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 110%;
    height: 2.5px;
    background-color: #684000;
  }
  .ship-wrap .fore-course {
    position: absolute;
    right: -2.2222222222px;
    top: -117.6470588235px;
    border-bottom: 80px solid #fff;
    border-right: 22.2222222222px solid transparent;
    border-top: 21.0526315789px solid transparent;
  }
  .ship-wrap .fore-course:after {
    position: absolute;
    right: 0;
    top: 0;
    width: 3px;
    height: 15px;
    background-color: red;
  }
  .ship-wrap .flag {
    background-color: red;
    position: absolute;
    top: -100px;
    right: 4px;
    width: 16.6666666667px;
    height: 6.6666666667px;
  }
  @-webkit-keyframes wave1 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    50% {
      -webkit-transform: translateX(-25%);
              transform: translateX(-25%);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  @keyframes wave1 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    50% {
      -webkit-transform: translateX(-25%);
              transform: translateX(-25%);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  @-webkit-keyframes wave2 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    50% {
      -webkit-transform: translateX(15%);
              transform: translateX(15%);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  @keyframes wave2 {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
    50% {
      -webkit-transform: translateX(15%);
              transform: translateX(15%);
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }
  @-webkit-keyframes ship {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    75% {
      -webkit-transform: rotate(-3deg);
              transform: rotate(-3deg);
    }
    100% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
  }
  @keyframes ship {
    0% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
    75% {
      -webkit-transform: rotate(-3deg);
              transform: rotate(-3deg);
    }
    100% {
      -webkit-transform: rotate(0);
              transform: rotate(0);
    }
  }
  
  