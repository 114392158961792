a {
  text-decoration: none;
}

.font_7 {
  font: normal normal normal 17px/1.4em helvetica-w01-light, helvetica-w02-light,
    sans-serif;
  color: #3b3a3a;
  font-size: 14px;
}

.font_9 {
  font: normal normal normal 14px/1.4em avenir-lt-w01_35-light1475496,
    sans-serif;
  color: #3b3a3a;
  line-height: 0em;
}

.font_0 {
  font: normal normal normal 23px/1.4em avenir-lt-w01_85-heavy1475544,
    sans-serif;
  color: #3b3a3a;
  font-weight: bold;
}
.logo-container {
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;
}

.logo-footer {
  width: 31px;
  height: 31px;
  line-height: 0em;
}

.header-container {
  width: 100%;
  position: sticky;
  padding-top: 5px;
  padding-left: 10%;
  height: 83px;
  margin-top: 50px;
  position: fixed; /* Set the navbar to fixed position */
  top: 0; /* Position the navbar at the top of the page */
  background-color: white;
  z-index: 99;
  border-bottom-color: #ddd;
  border-bottom-width: 4px;
  border-bottom-style: solid;
}

@media only screen and (max-width: 767px) {
  .header-container {
    position: relative;
  }
}

.header-logo {
  object-position: 50% center;
  width: 92px;
  height: 68px;
  object-fit: cover;
  float: left;
}

.header-item {
  display: inline;
  word-wrap: break-word;
  text-align: start;
  float: left;
  margin-top: 5px;
  margin-left: 20px;
}
