@media screen and (min-width: 768px) {
  body {
    font-size: 16px;
    padding-top: 50px;
  }
  h1 {
    font-size: 3em;
  }
  h2 {
    font-size: 2.25em;
  }
  h3 {
    font-size: 1.75em;
  }
  h4 {
    font-size: 1.125em;
  }
}

@media screen and (max-width: 767px) {
  body {
    font-size: 14px;
    padding-top: 50px;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.625em;
  }
  h3 {
    font-size: 1.375em;
  }
  h4 {
    font-size: 1.125em;
  }
}
