.container-margin {
  margin-top: 0;
}

.form-container {
  padding: 40px;
}

@media only screen and (min-width: 992px) {
  .container-margin {
    margin-top: 100px;
  }

  .form-container {
    background-color: white;
    padding: 40px;
    border-width: 3px;
    border-style: solid;
    border-color: rgba(192, 210, 229, 1);
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
